.customLinkModalClass {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(35%, -50%);
  width: auto;
  height: 230px !important;
}
@media (max-width: 768px) {
  .customLinkModalClass {
    transform: translate(-65%, -50%);
  }
}
.customLinkModalClass .rdw-link-modal-btn {
  width: 85px;
  border-radius: 5px;
}
.customLinkModalClass .rdw-link-modal-btn:first-child {
  background-color: #0891b2;
  color: #fff;
  margin-left: 0px;
}
.customLinkModalClass .rdw-link-modal-btn:first-child:hover {
  background-color: rgb(52, 113, 141);
  box-shadow: none;
}
.customLinkModalClass .rdw-link-modal-btn:last-child {
  border: solid 1px #d1d5db;
  color: #000;
}
.customLinkModalClass .rdw-link-modal-btn:last-child:hover {
  background-color: rgb(249, 250, 251);
  box-shadow: none;
}
.content-editor h1 {
  font-size: 2em;
}
.content-editor h2 {
  font-size: 1.5em;
}
.content-editor h3 {
  font-size: 1.17em;
}
.content-editor h4 {
  font-size: 1em;
}
.content-editor h5 {
  font-size: 0.83em;
}
.content-editor h6 {
  font-size: 0.67em;
}
.rdw-left-aligned-block,
.rdw-right-aligned-block,
.rdw-center-aligned-block {
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.rdw-left-aligned-block {
  justify-content: flex-start;
}
.rdw-right-aligned-block {
  justify-content: flex-end;
}
.rdw-center-aligned-block {
  justify-content: center;
}
